import React from 'react';
import { IonItem, IonLabel, IonAvatar, IonImg } from '@ionic/react';

interface ItemProps {
    business: Business,
    onSelect: Function
}



const SearchListItem: React.FC<ItemProps> = ({ business, onSelect }) => {
    return (
        <IonItem key={business.id} className="avatar-item" onClick={()=>{onSelect(business)}}>
            <IonAvatar slot="start">
                <IonImg src={(business.avatar_url) ? business.avatar_url : '/assets/avatar.svg'} />
            </IonAvatar>
            <IonLabel>
                <h1>{business.name}</h1>
                <h6>{String(business.category_names).toUpperCase()}</h6>
                <p>{business.cities.join('/')}</p>
            </IonLabel>
        </IonItem>);
};

export default SearchListItem;
