import React from 'react';
import { IonItem, IonText, IonLabel, IonButton, IonIcon, IonCard, IonCardHeader, IonImg } from '@ionic/react';
import './BookingItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';
import { closeCircleOutline, trashOutline } from 'ionicons/icons';
import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    booking: Booking,
    deleteAction: Function
}


const BookingItem: React.FC<ItemProps> = ({ booking, deleteAction }) => {
    return (
        <IonItem>
            <IonButton onClick={() => deleteAction(booking)} size="default" color="danger" fill="clear" slot="end"><IonIcon icon={trashOutline}></IonIcon></IonButton>

            <IonLabel className="ion-text-wrap spaced-label">
                <IonText>
                    <h2 className="booking-date"><Moment locale="it" format="dddd D MMMM HH:mm">{booking.date + ' '+booking.start}</Moment></h2>
                    <h2 className="booking-details">{booking.service.name} {(booking.service.type!=='access')?'con' + booking.operator.name:''}</h2>
                    <p className="booking-notes">{booking.shop.name} </p>
                    {/* <p className="booking-notes">Durata prevista: {booking.service.duration} minuti {(booking.service.price) && <CurrencyFormat value={booking.service.price} decimalSeparator={','} displayType={'text'} prefix={'€'} /> */}
                </IonText>
            </IonLabel>
        </IonItem>
    );
};

export default BookingItem;
