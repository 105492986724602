import React from 'react';
import { IonItem, IonText, IonLabel, IonNote, IonInput, IonIcon, IonList, IonListHeader } from '@ionic/react';
import { arrowForwardSharp, business } from 'ionicons/icons';
import SearchListItem from './SearchListItem';
// import './ExploreContainer.css';
// import './SearchList.css'

interface ItemProps {
    results: Business[],
    onSelect:Function
}


const SearchList: React.FC<ItemProps> = ({ results, onSelect }) => {
    return (
        <IonList>
            <IonListHeader>Risultati</IonListHeader>
            {results.map(business => (<SearchListItem key={business.id} business={business} onSelect={(business:Business)=>onSelect(business)}></SearchListItem>))}
        </IonList>
    );
};

export default SearchList;
