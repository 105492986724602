import React, { useState, useEffect, useReducer, createContext, useContext } from 'react';
import { IonApp, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';

import SelectShop from './pages/NewBooking/SelectShop';
import MyProfile from './pages/MyProfile';

// //Capacitor
// import { Plugins } from '@capacitor/core';

import GeneralHome from './pages/GeneralHome/GeneralHome';
import BusinessSearch from './pages/BusinessSearch/BusinessSearch';
import { personOutline, calendarOutline, searchOutline } from 'ionicons/icons';
import MyBookings from './pages/MyBookings';
import { AppContextProvider } from './AppContextProvider';
import PushService from './shared/lib/services/PushService';
import Home from './pages/Custom/Home/Home';
import BusinessPage from './pages/Custom/BusinessPage/BusinessPage';

//Register for push
PushService.register();
PushService.getToken();

const App: React.FC = () => {

  const HomePage = (process.env.REACT_APP_TYPE == "retail") ? Home : GeneralHome;

  return (

    <IonApp>
      <AppContextProvider>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route path="/home" component={HomePage} exact={true} />
              <Route path="/my-bookings" component={MyBookings} exact={true} />
              <Route path="/profile" component={MyProfile} exact={true} />
              <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
              <Route path="/custom-shop-booking" component={BusinessPage} exact={true} />


              <Route path="/search-business" component={BusinessSearch} exact />
              <Route path="/business/:customslug" component={BusinessSearch} exact />
              <Route path="/new-booking/shop/" component={SelectShop} />

            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="tab1" href="/home">
                <IonIcon icon={searchOutline} />
              </IonTabButton>
              <IonTabButton tab="tab2" href="/my-bookings">
                <IonIcon icon={calendarOutline} />
              </IonTabButton>
              <IonTabButton tab="tab3" href="/profile">
                <IonIcon icon={personOutline} />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </AppContextProvider>
    </IonApp>
  );
};

export default App;

//Toggle dark and/or custom theme
//document.body.classList.toggle('dark', true);
// document.body.classList.toggle('crazy', true);
