import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import ShopsList from '../../components/Shops/ShopsList';

const SelectShop: React.FC = () => {

    const app_id = 1;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Sede</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
          <IonTitle size="large">Sede</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <ShopsList appId={app_id}></ShopsList> */}
      </IonContent>
    </IonPage>
  );
};

export default SelectShop;
