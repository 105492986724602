import React, { useState, useEffect } from 'react';



const Emoji: any = () => {



    const emojis = ['💅', '💇‍♂️', '👨‍⚕️', '🦷', '💇‍♀️', '👨‍💼'];
    const [count, setCount] = useState(0);

    useEffect(() => {
        const id = setInterval(() => {
            setCount(c => (c+1 < emojis.length)?c + 1:0);
        }, 2000);
        return () => clearInterval(id);
    }, []);
    //setInterval(()=>{setEmoji(emojis[(Math.floor(Math.random() * emojis.length))]);},2000);
    //setInterval(() => setEmoji(emojis[(Math.floor(Math.random() * emojis.length))]), 1000);

    return (
        emojis[count]
    );
};

export default Emoji;
