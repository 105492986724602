import React from 'react';
import { IonList, IonListHeader, IonSlides, IonItem, IonText, IonCard, IonCardHeader, IonImg } from '@ionic/react';
import DateItem from './BookingItem';
import './BookingList.css';
import BookingService from '../../shared/lib/services/BookingService';
import { Plugins } from '@capacitor/core';
const { Modals } = Plugins;


interface ItemProps {
    bookings: Booking[],
    onDelete: Function,
    tokens:any
}

const onBookingSelected = () => { };

const BookingsList: React.FC<ItemProps> = ({ bookings, onDelete, tokens }) => {

    const showDeleteConfirm: Function = async (booking: Booking) => {
        let confirmRet = await Modals.confirm({
            title: 'Annulla',
            message: 'Sei sicuro di voler annullare la prenotazione?'
        });
        if (confirmRet.value == true) {
            BookingService.cancel(booking.id, tokens).then(() => {
                onDelete();
            })
        }
    }

    return (
        <IonList className="ion-padding-top">
            {/* <IonListHeader>
                I tuoi prossimi appuntamenti
            </IonListHeader> */}
            {(bookings.length < 1 && 
            <div>
                <IonText className="ion-text-center ion-margin-top ion-padding">
                    <h1 className="icon-placeholder">🗓</h1>
                    <p className="ion-padding">Ancora nessun appuntamento.<br></br>Cosa aspetti?</p>
                </IonText>
            </div>)}

            {bookings.map(booking => (
                <DateItem key={booking.id} booking={booking} deleteAction={(selected: Booking) => { showDeleteConfirm(selected) }} />
            ))}
        </IonList>
    );
};

export default BookingsList;
