import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonList, IonItemDivider, IonButton, IonModal, IonText, IonButtons } from '@ionic/react';
import React, { useState, useContext } from 'react';
import AuthService, { Credentials } from '../../shared/lib/services/AuthService';

import { Plugins } from '@capacitor/core';
import { AppContext } from '../../AppContextProvider';


const { Modals, Storage } = Plugins;

interface ItemProps {
    onAccess: Function,
    toRegister: Function,
    dismiss: Function

}

export const LoginViewComponent: React.FC<ItemProps> = ({ onAccess, toRegister, dismiss }) => {

    const [credentials, setCredentials] = useState<Credentials>({});
    const [validForm, setValidForm] = useState<boolean>(false);

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;


    const updateCredentials = (credential: 'email' | 'password', value: string | undefined | null) => {
        let cred: Credentials = credentials; cred[credential] = value;
        setCredentials(cred);
        checkFields();
    }
    const checkFields = () => {
        let valid = true;
        if (!credentials?.email || !credentials.password) valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            AuthService.login({ email: credentials.email, password: credentials.password }).then((data: any) => {
                console.log('ON LOGIN', data);
                if (data && data.user) {
                    dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: { access_token: data.access_token, refresh_token: data.refresh_token }, user: data.user } });
                    storeAuth(data);
                    onAccess();
                }
            });
        }
    }

    const storeAuth = async (data: any) => {
        Storage.set({
            key: 'tokens',
            value: JSON.stringify({ access_token: data.access_token, refresh_token: data.refresh_token })
        });
        Storage.set({
            key: 'user',
            value: JSON.stringify(data.user)
        });
        // console.log(data.user);
    }

    const showForgotModal = async () => {
        let promptRet = await Modals.prompt({
            title: 'Recupero password',
            message: 'Inserisci l\'email'
        });
        if (!promptRet.cancelled && promptRet.value) {
            AuthService.resetPassword(promptRet.value);
        }
    }

    return (
        <div className="ion-page">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Effettua l'accesso</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => { dismiss(); }}>
                            Annulla
                </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList lines="full" className="ion-margin ion-padding">
                    <IonItem>
                        <IonLabel position="stacked">E-mail</IonLabel>
                        <IonInput onIonChange={(e) => updateCredentials('email', e.detail.value)} type="email"></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel position="stacked">Password</IonLabel>
                        <IonInput onIonChange={(e) => updateCredentials('password', e.detail.value)} type="password"> </IonInput>
                    </IonItem>
                    <IonButton disabled={!validForm} onClick={() => { submit(); }} className="ion-margin-top" expand="block">Accedi</IonButton>
                    <IonButton onClick={() => { showForgotModal(); }} className="ion-margin-top" fill="clear" color="dark" expand="block">Password dimenticata?</IonButton>
                    <hr style={{ color: 'gray', background: 'gray', width: '70%' }}></hr>
                    <IonText className="ion-text-center" color="dark">
                        <p>Non hai un account?</p>
                    </IonText>
                    <IonButton onClick={() => { toRegister() }} className="ion-margin-top" fill="clear" color="primary" expand="block">Registrati adesso</IonButton>
                </IonList>
            </IonContent>
        </div>
    );
};