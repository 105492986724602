import React from 'react';
import { IonList, IonListHeader, IonText } from '@ionic/react';
import DateItem from './SlotItem';
import { Plugins } from '@capacitor/core';
import SlotItem from './SlotItem';
const { Modals } = Plugins;

const showBookConfirm: Function = async (slot: AvailableSlot) => {
    let confirmRet = await Modals.confirm({
        title: 'Prenotazione',
        message: 'Sei sicuro di voler prenotare per le ' + slot.time + '?'
    });
    console.log('Confirm ret', confirmRet);
}


interface ListProps {
    slots: string[],
    onSelection: Function
}

const SlotsList: React.FC<ListProps> = ({ onSelection, slots }) => {

    return (
        <IonList>
            <IonListHeader>
                Orari ancora disponibili
            </IonListHeader>
            {(slots.length == 0 &&
                <div className="ion-margin-top ion-text-center">
                    <IonText className="ion-margin-top">Non ci sono più orari per questa data</IonText>
                </div>
            )}
            {slots.map(slot => (
                <SlotItem key={slot} slot={slot} action={(selected: string) => { onSelection(selected); }} />
            ))}
        </IonList>

    );
};

export default SlotsList;
