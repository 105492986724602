import React from 'react';
import { IonList, IonListHeader } from '@ionic/react';
import OperatorItem from './OperatorItem';

interface ListProps {
    operators: Operator[],
    onSelection: Function
}

const OperatorsList: React.FC<ListProps> = ({ onSelection, operators }) => {
    return (
        <IonList>
            <IonListHeader>
                Seleziona operatore
            </IonListHeader>
            {operators.map(operator => (
                <OperatorItem key={operator.id} operator={operator} action={(selected: Operator) => { onSelection(selected) }} />
            ))}
        </IonList>
    );
};

export default OperatorsList;
