import React from 'react';
import { IonItem, IonText, IonLabel, IonNote } from '@ionic/react';
import './DateItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';

interface ItemProps {
    date: string,
    action: Function
}


const DateItem: React.FC<ItemProps> = ({ date, action }) => {
    return (

        <IonItem onClick={() => action(date)}>
            <IonLabel className="ion-text-wrap spaced-label">
                <IonText>
                    <Moment locale="it" format="dddd D MMMM">{date}</Moment>
                </IonText>
            </IonLabel>
        </IonItem>
    );
};

export default DateItem;
