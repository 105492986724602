import React from 'react';
import { IonItem, IonLabel, IonAvatar, IonImg } from '@ionic/react';
import './OperatorItem.css';

interface ItemProps {
    operator: Operator,
    action: Function
}


const OperatorItem: React.FC<ItemProps> = ({ operator, action }) => {
    return (
        <IonItem className="avatar-item" onClick={() => { action(operator); }} >
            <IonAvatar slot="start">
                <IonImg src={(operator.avatar) ? operator.avatar : '/assets/avatar.svg'} />
            </IonAvatar>
            <IonLabel>
                <h1>{operator.name}</h1>
            </IonLabel>
        </IonItem>);
};

export default OperatorItem;
