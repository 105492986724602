import { Plugins } from '@capacitor/core';
import { FCM } from "capacitor-fcm";

const { PushNotifications } = Plugins;

//
// with type support
const fcm = new FCM();

function register() {
    //
    // external required step
    // register for push
    PushNotifications.register()
        .then(() => {
            //
            // Subscribe to a specific topic
            // you can use `FCMPlugin` or just `fcm`
            fcm
                .subscribeTo({ topic: "general" })
                .then(r => console.log(`subscribed to topic`))
                .catch(err => console.log(err));
        })
        .catch(err => console.log(JSON.stringify(err)));
}

function unsubscribe() {
    //
    // Unsubscribe from a specific topic
    fcm
        .unsubscribeFrom({ topic: "general" })
        .then(() => console.log(`unsubscribed from topic`))
        .catch(err => console.log(err));
}

function getToken() {
    //
    // Get FCM token instead the APN one returned by Capacitor
    fcm
        .getToken()
        .then(r => console.log(`Token ${r.token}`))
        .catch(err => console.log(err));
}

function removeInstance() {
    //
    // Remove FCM instance
    fcm
        .deleteInstance()
        .then(() => console.log(`Token deleted`))
        .catch(err => console.log(err));
}

const PushService = {
    register, unsubscribe, getToken, removeInstance
};

export default PushService;