import React from 'react';
import { IonItem, IonText, IonLabel, IonNote } from '@ionic/react';
// import './ExploreContainer.css';

interface ItemProps {
    service: Service,
    action: Function
}


const ServiceItem: React.FC<ItemProps> = ({ service, action }) => {
    return (

        <IonItem onClick={() => { action(service) }}>
            <IonLabel className="ion-text-wrap spaced-label">
                <IonText>
                    <h1>{service.name}</h1>
                    <p>{service.duration} minuti</p>
                </IonText>
            </IonLabel>
            {!(service.price && service.price > 0) || <IonNote>
                <IonText>
                    € {service.price}
                </IonText>
            </IonNote>}
        </IonItem>
    );
};

export default ServiceItem;
