/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios'
// import constants from './constants'


import { Plugins } from '@capacitor/core';


/**
 * Create an Axios Client with defaults
 */
//console.log(process.env.REACT_APP_BASEURL);
const client = axios.create({
    baseURL: process.env.REACT_APP_BASEURL
});

const { Modals, Storage } = Plugins;


/**
 * Request Wrapper with default success/error actions
 */
const request = function (options: any, tokens: any = null) {

    //check for access token
    //TO DO: use a global state manager to check the token


    client.interceptors.request.use(
        config => {
            // Add access token to every request
            if (tokens) config.headers["Authorization"] = "Bearer " + tokens.access_token;
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );




    const onSuccess = function (response: any) {
        console.debug('Request Successful!', response);
        return response.data;
    }

    const onError = function (error: any) {
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);

            switch (error.response.status) {
                case 500:
                    showError();
                    break;
                // case 400:
                //     showError();
                //     break;
            }


        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    }

    const showError = async () => {
        let alert = await Modals.alert({
            title: 'Errore',
            message: 'Si è verificato un errore nel caricamento'
        });
    }

    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export default request;