import React, { useState } from 'react';
import { IonList, IonListHeader } from '@ionic/react';
import ServiceItem from './ServiceItem';
// import './ExploreContainer.css';

interface ListProps {
    services: Service[],
    onSelection: Function
}


const ServicesList: React.FC<ListProps> = ({ onSelection, services }) => {
    

    return (
        <IonList>
            <IonListHeader>
                Scegli un servizio
            </IonListHeader>
            {services?.map(service => (
                <ServiceItem key={service.id} service={service} action={(service: Service) => { onSelection(service) }} />
            ))}
        </IonList>
    );
};

export default ServicesList;
