import React from 'react';
import { IonItem, IonText, IonLabel, IonNote, IonInput, IonIcon } from '@ionic/react';
import { arrowForwardSharp } from 'ionicons/icons';
// import './ExploreContainer.css';
import './SearchInput.css'

interface ItemProps {
    disabled: boolean,
    onChange: Function,
    placeholder?: string,
    link?: string
}


const SearchInput: React.FC<ItemProps> = ({ disabled, onChange, placeholder, link }) => {
    return (
        <IonItem lines={(disabled)?'inset':"none"} detail={disabled} detailIcon={arrowForwardSharp} routerLink={link}>
            {(!disabled && <IonInput onIonChange={(e) => { onChange(e.detail.value) }} className="custom-input" placeholder={placeholder} disabled={disabled}>
            </IonInput>)}
            {(!disabled && <IonNote>
                <IonIcon icon={arrowForwardSharp}></IonIcon>
            </IonNote>)}
        </IonItem>
    );
};

export default SearchInput;
