import UserService from './UserService';
import request from '../request';



/* Booking Flow requests */
function getShops(app_id: string | number) {
    return request({
        url: `booking/app/${app_id}/shop`,
        method: 'GET'
    });
}

function getServices(app_id: string | number, shop_id: string | number) {
    return request({
        url: `booking/app/${app_id}/shop/${shop_id}/service`,
        method: 'GET'
    });
}

function getOperators(app_id: string | number, shop_id: string | number, service_id: string | number) {
    return request({
        url: `booking/app/${app_id}/shop/${shop_id}/service/${service_id}/operator`,
        method: 'GET'
    });
}

function getDates(app_id: string | number, shop_id: string | number) {
    return request({
        url: `booking/app/${app_id}/shop/${shop_id}/dates`,
        method: 'GET'
    });
}

function getSlots(app_id: string | number, shop_id: string | number, service_id: string | number, operator_id: string | number, date: string) {
    return request({
        url: `booking/app/${app_id}/shop/${shop_id}/service/${service_id}/operator/${operator_id}/date/${date}/slot`,
        method: 'GET'
    });
}

function create(booking: NewBooking, tokens:any) {
    return request({
        url: `booking`,
        method: 'POST',
        data: booking
    }, tokens);
}

function cancel(booking_id: string | number, tokens:any) {
    return request({
        url: `booking/${booking_id}`,
        method: 'DELETE'
    }, tokens);
}



const AppsService = {
    getShops, getServices, getOperators, getDates, getSlots, create, cancel
}

export default AppsService;