import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import BookingsList from '../components/Bookings/BookingsList';
import { Plugins } from '@capacitor/core';
import { book } from 'ionicons/icons';
import UserService from '../shared/lib/services/UserService';
import { AppContext } from '../AppContextProvider';

const { Storage } = Plugins;

const MyBookings: React.FC = () => {

    const globalState = useContext(AppContext);
    let { dispatch } = globalState;
    let { state } = globalState;


    const updateUser = async () => {
        UserService.get(state.tokens).then((data: any) => {
            let tokens = state.tokens;
            dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: tokens, user: data } });
            storeUser(data);
        });
    }
    const storeUser = async (data: User) => {
        Storage.set({
            key: 'user',
            value: JSON.stringify(data)
        });
    };
    
    useEffect(() => {
        console.log('loaded state from my-booking: ', globalState);
    }, [globalState]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Prenotazioni</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Prenotazioni</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {(state &&
                    <BookingsList tokens={state.tokens} onDelete={() => { updateUser(); }} bookings={(state.user && state.user.active_bookings) ?? []}></BookingsList>
                )}
            </IonContent>
        </IonPage>
    );
};

export default MyBookings;
