import request from '../request'
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;



function get(tokens: any) {
    return request({
        url: `/user`,
        method: 'GET'
    }, tokens);
}


const storeUser = async (data: User) => {
    await Storage.set({
        key: 'user',
        value: JSON.stringify(data)
    });
};



const UserService = {
    get
};

export default UserService;