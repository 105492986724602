import React from 'react';
import { IonList, IonListHeader } from '@ionic/react';
import ShopItem from './ShopItem';
// import './ExploreContainer.css';

interface ListProps {
    shops: Shop[],
    onSelection: Function
}

const ShopsList: React.FC<ListProps> = ({ onSelection, shops }) => {

    return (
        <IonList className="full-list">
            <IonListHeader>
                Scegli una sede
            </IonListHeader>
            {shops?.map(shop => (
                <ShopItem key={shop.id} shop={shop} action={(shop: Shop) => { onSelection(shop) }} />
            ))}
        </IonList>
    );
};

export default ShopsList;
