import React from 'react';
import { IonList, IonListHeader } from '@ionic/react';
import DateItem from './DateItem';
// import './ExploreContainer.css';
import moment from "moment";
import 'moment/locale/it';


interface ListProps {
    dates: string[],
    onSelection: Function
}


const onDateSelected = () => { };

const DatesList: React.FC<ListProps> = ({ onSelection, dates }) => {
    return (
        <IonList>
            <IonListHeader>
                Date disponibili
            </IonListHeader>
            {dates.map(date => (
                <DateItem key={date} date={date} action={(selected: string) => { onSelection(selected) }} />
            ))}
        </IonList>
    );
};

export default DatesList;
