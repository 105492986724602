import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonAvatar, IonLabel, IonListHeader, IonIcon, IonButton, IonImg, IonModal, IonText } from '@ionic/react';
import React, { useEffect, useState, useContext } from 'react';
import { receiptOutline, personCircleOutline, helpBuoyOutline, logOutOutline } from 'ionicons/icons';
import { Plugins } from '@capacitor/core';
import AuthService from '../shared/lib/services/AuthService';
import { AppContext } from '../AppContextProvider';
import { LoginViewComponent } from '../components/Auth/LoginViewComponent';
import RegisterViewComponent from '../components/Auth/RegisterViewComponent';

const { Storage } = Plugins;


const MyProfile: React.FC = () => {

    const [loginOpen, setLoginOpen] = useState<boolean>(false);
    const [registerOpen, setRegisterOpen] = useState<boolean>(false);

    const globalState = useContext(AppContext);
    let { dispatch } = globalState;
    let { state } = globalState;

    const logout = () => {
        clearAuth();
        dispatch({ type: 'REMOVE_AUTH', payload: {} });
        AuthService.logout(state.tokens);
    }

    const clearAuth = async () => {
        await Storage.remove({
            key: 'tokens'
        });
        await Storage.remove({
            key: 'user',
        });
    }

    useEffect(() => {
        console.log('loaded state from my-profile: ', globalState);
    }, [globalState]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Profilo</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Profilo</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {((state.user &&
                    <div>
                        <IonItem className="avatar-item">
                            <IonAvatar className="profile-avatar" slot="start">
                                <IonImg src={(state.user.avatar) ?? "/assets/avatar.svg"} />
                            </IonAvatar>
                            <IonLabel>
                                <h1 className="user-name">{state.user.name}</h1>
                                <h4>{state.user.email}</h4>
                            </IonLabel>
                        </IonItem>
                        {/* <IonListHeader>
                            <IonLabel>Dettagli</IonLabel>
                        </IonListHeader>
                        <IonItem className="profile-item">
                    <IonLabel>
                        Storico dei tuoi ordini
                    </IonLabel>
                    <IonIcon icon={receiptOutline} slot="end" />
                </IonItem> 
                        <IonItem routerLink="/edit-profile" className="profile-item">
                            <IonLabel>
                                Modifica informazioni
                    </IonLabel>
                            <IonIcon icon={personCircleOutline} slot="end" />
                        </IonItem>
                        {/* <IonItem className="profile-item">
                    <IonLabel>
                        Assistenza
                </IonLabel>
                    <IonIcon icon={helpBuoyOutline} slot="end" />
                </IonItem> */}
                        <IonButton onClick={() => logout()} expand="full" className="ion-margin-top" fill="clear" color="danger">
                            Log-out <IonIcon slot="end" color="danger" icon={logOutOutline} />
                        </IonButton>
                    </div>)
                    ??
                    <div className="ion-padding ion-text-center">
                        <IonText className="ion-margin-top ion-margin-bottom">
                            <h3>Per visualizzare il tuo profilo, effettua l'accesso.</h3>
                        </IonText>
                        <IonButton className="ion-margin-top" onClick={() => { setLoginOpen(true); }} expand="block" color="primary">Accedi o registrati</IonButton>
                    </div>)}
                <IonModal isOpen={loginOpen}>
                    <LoginViewComponent dismiss={() => { setLoginOpen(false); }} toRegister={() => { setLoginOpen(false); setRegisterOpen(true); }} onAccess={() => { setLoginOpen(false); }}></LoginViewComponent>
                </IonModal>
                <IonModal isOpen={registerOpen}>
                    <RegisterViewComponent dismiss={() => { setRegisterOpen(false); }} toLogin={() => { setLoginOpen(true); setRegisterOpen(false); }} onAccess={() => { setRegisterOpen(false); }}></RegisterViewComponent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default MyProfile;
