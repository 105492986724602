import React, { useEffect, useState, useContext } from 'react';
import { IonText, IonIcon, IonContent, IonCardHeader, IonCard, IonHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonToolbar, IonButtons, IonModal, IonGrid, IonRow, IonCol, IonPage, IonTitle } from '@ionic/react';
import { closeCircleOutline, heartOutline, callOutline, callSharp, textSharp, mailSharp, business } from 'ionicons/icons';


import { Plugins } from '@capacitor/core';
import { AppContext } from '../../../AppContextProvider';
import UserService from '../../../shared/lib/services/UserService';
import BookingCardFlow from '../../../components/BookingCardFlow/BookingCardFlow';
import BookingConfirmationComponent from '../../../components/Bookings/BookingConfirmationComponent';
import { LoginViewComponent } from '../../../components/Auth/LoginViewComponent';
import RegisterViewComponent from '../../../components/Auth/RegisterViewComponent';

import shop_configuration from '../../../shop-configuration';

const { Storage } = Plugins;


const BusinessPage: React.FC = () => {

    const globalState = useContext(AppContext);
    const { state } = globalState;
    const { dispatch } = globalState;

    const updateUser = async () => {
        UserService.get(state.tokens).then((data: any) => {
            //console.log(data);
            let tokens = state.tokens;
            dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: tokens, user: data } });
            storeUser(data);
        });
    }
    const storeUser = async (data: User) => {
        Storage.set({
            key: 'user',
            value: JSON.stringify(data)
        });
    };

    const [loginOpen, setLoginOpen] = useState<boolean>(false);
    const [registerOpen, setRegisterOpen] = useState<boolean>(false);

    const [confirmedBooking, setConfirmedBooking] = useState<Booking>();
    const showBookingConfirmation = (booking: Booking) => {
        setConfirmedBooking(booking);
    }
    useEffect(() => {
        console.log('updated state from business page: ', globalState);
    }, [globalState]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Nuova prenotazione
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {((state && state.user) &&
                    <BookingCardFlow onBookingComplete={(booking: Booking) => { showBookingConfirmation(booking); updateUser(); }} business={shop_configuration}></BookingCardFlow>
                ) ??
                    <div className="ion-padding ion-text-center">
                        <IonButton className="" onClick={() => { setLoginOpen(true); }} expand="block" color="primary">Accedi e prenota</IonButton>
                    </div>
                }
                <IonModal cssClass='confirm-modal' isOpen={confirmedBooking !== undefined}>
                    {(confirmedBooking && <BookingConfirmationComponent booking={confirmedBooking} close={() => { setConfirmedBooking(undefined) }}></BookingConfirmationComponent>)}
                </IonModal>
                <IonModal isOpen={loginOpen}>
                    <LoginViewComponent dismiss={() => { setLoginOpen(false); }} toRegister={() => { setLoginOpen(false); setRegisterOpen(true); }} onAccess={() => { setLoginOpen(false); }}></LoginViewComponent>
                </IonModal>
                <IonModal isOpen={registerOpen}>
                    <RegisterViewComponent dismiss={() => { setRegisterOpen(false); }} toLogin={() => { setLoginOpen(true); setRegisterOpen(false); }} onAccess={() => { setRegisterOpen(false); }}></RegisterViewComponent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default BusinessPage;
