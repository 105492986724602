import React, { useEffect, useState, useContext } from 'react';
import { IonText, IonIcon, IonContent, IonCardHeader, IonCard, IonHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButton, IonToolbar, IonButtons, IonModal, IonGrid, IonRow, IonCol } from '@ionic/react';
import { closeCircleOutline, heartOutline, callOutline, callSharp, textSharp, mailSharp } from 'ionicons/icons';
import './BusinessPageComponent.scss'

import BookingCardFlow from '../BookingCardFlow/BookingCardFlow';

import { Plugins } from '@capacitor/core';
import { AuthViewComponent } from '../Auth/AuthViewComponent';
import BookingConfirmationComponent from '../Bookings/BookingConfirmationComponent';
import BusinessPage from '../../pages/BusinessPage/BusinessPage';
import UserService from '../../shared/lib/services/UserService';
import { AppContext } from '../../AppContextProvider';
import { LoginViewComponent } from '../Auth/LoginViewComponent';
import RegisterViewComponent from '../Auth/RegisterViewComponent';
const { Storage } = Plugins;


interface ItemProps {
    business: Business,
    onClose: Function
}


const BusinessPageComponent: React.FC<ItemProps> = ({ business, onClose }) => {

    const globalState = useContext(AppContext);
    const { state } = globalState;
    const { dispatch } = globalState;

    const updateUser = async () => {
        UserService.get(state.tokens).then((data: any) => {
            //console.log(data);
            let tokens = state.tokens;
            dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: tokens, user: data } });
            storeUser(data);
        });
    }
    const storeUser = async (data: User) => {
        Storage.set({
            key: 'user',
            value: JSON.stringify(data)
        });
    };

    const [loginOpen, setLoginOpen] = useState<boolean>(false);
    const [registerOpen, setRegisterOpen] = useState<boolean>(false);

    const [confirmedBooking, setConfirmedBooking] = useState<Booking>();
    const showBookingConfirmation = (booking: Booking) => {
        setConfirmedBooking(booking);
    }
    useEffect(() => {
        console.log('updated state from business page: ', globalState);
    }, [globalState]);

    return (

        <IonContent id="business-page">
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => onClose()} color="white" fill="clear"><IonIcon icon={closeCircleOutline}></IonIcon></IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        {/* <IonButton onClick={() => { }} color="white" fill="clear"><IonIcon icon={heartOutline}></IonIcon></IonButton> */}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <div className="about-header">
                {/* Instead of loading an image each time the select changes, use opacity to transition them */}
                <div className="about-image" style={{ background: 'url(' + business?.avatar_url + ')', backgroundPosition: 'center center', backgroundSize: 'cover' }}></div>
            </div>
            <IonCard className="business-card">
                <IonCardHeader>
                    <IonCardTitle>{business?.name}</IonCardTitle>
                    <IonCardSubtitle>{business?.category_names}</IonCardSubtitle>
                    {/* <p>{business.cities}</p> */}
                </IonCardHeader>
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol>

                            </IonCol>
                            <IonCol className="ion-text-right">
                                {(business.phone && <IonButton onClick={() => { window.open('tel:'+business.phone, '_blank'); }} color="primary" fill="clear"><IonIcon icon={callSharp}></IonIcon></IonButton>)}
                                {(business.email && <IonButton onClick={() => {window.open('mailto:'+business.email, '_blank'); }} color="primary" fill="clear"><IonIcon icon={mailSharp}></IonIcon></IonButton>)}

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </IonCard>

            <div className="new-booking-header">
                <IonCardSubtitle>NUOVA PRENOTAZIONE</IonCardSubtitle>
            </div>
            {((state && state.user) &&
                <BookingCardFlow onBookingComplete={(booking: Booking) => { showBookingConfirmation(booking); updateUser(); }} business={business}></BookingCardFlow>
            ) ??
                <div className="ion-padding ion-text-center">
                    <IonButton className="" onClick={() => { setLoginOpen(true); }} expand="block" color="primary">Accedi e prenota</IonButton>
                </div>
            }
            <IonModal cssClass='confirm-modal' isOpen={confirmedBooking !== undefined}>
                {(confirmedBooking && <BookingConfirmationComponent booking={confirmedBooking} close={() => { setConfirmedBooking(undefined) }}></BookingConfirmationComponent>)}
            </IonModal>
            <IonModal isOpen={loginOpen}>
                <LoginViewComponent dismiss={() => { setLoginOpen(false); }} toRegister={() => { setLoginOpen(false); setRegisterOpen(true); }} onAccess={() => { setLoginOpen(false); }}></LoginViewComponent>
            </IonModal>
            <IonModal isOpen={registerOpen}>
                <RegisterViewComponent dismiss={() => { setRegisterOpen(false); }} toLogin={() => { setLoginOpen(true); setRegisterOpen(false); }} onAccess={() => { setRegisterOpen(false); }}></RegisterViewComponent>
            </IonModal>


        </IonContent>
    );
};

export default BusinessPageComponent;
