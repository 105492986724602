import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, IonIcon, IonSlides, IonSlide, IonText, IonGrid, IonRow, IonCol, IonModal } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import './GeneralHome.css';
import { calendarSharp, calendarOutline } from 'ionicons/icons';
import SearchInput from '../../components/BusinessSearch/SearchInput/SearchInput';
import { randomBytes } from 'crypto';
import Emoji from '../../components/Animations/Emoji';
import BusinessSearch from '../BusinessSearch/BusinessSearch';
import UserService from '../../shared/lib/services/UserService';
import { AppContext } from '../../AppContextProvider';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

const modal = <IonModal children={BusinessSearch} isOpen={false}></IonModal>;



const GeneralHome: React.FC = () => {

    const globalState = useContext(AppContext);
    const { state } = globalState;
    const { dispatch } = globalState;

    const updateUser = async () => {
        UserService.get(state.tokens).then((data: any) => {
            //console.log(data);
            let tokens = state.tokens;
            dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: tokens, user: data } });
            storeUser(data);
            console.log('user updated', data);
        });
    }
    const storeUser = async (data: User) => {
        Storage.set({
            key: 'user',
            value: JSON.stringify(data)
        });
    };

    useEffect(()=>{
        updateUser();
    },[]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonText>
                        <h1 className='logo-home ion-margin hue-effect'>AgendaFacile</h1>
                    </IonText>
                </IonToolbar>

            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonText className="ion-padding">
                                <h1 className="welcome-text ion-padding gradient-effect">Ciao,<br></br>cerca qui l'attività per cui prenotare<br></br></h1>
                                <h1 className="welcome-text emoji"><Emoji /></h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <SearchInput onChange={() => { }} link="/search-business" disabled={true}></SearchInput>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default GeneralHome;
