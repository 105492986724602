import { IonContent, IonHeader, IonPage, IonButton, IonImg, IonModal, IonToolbar, IonTitle, IonButtons, IonText } from '@ionic/react';
import React, { useContext, useState, useEffect } from 'react';
import './Home.css';
import BusinessPageComponent from '../../../components/BusinessPageComponent/BusinessPageComponent';
import BookingCardFlow from '../../../components/BookingCardFlow/BookingCardFlow';
import shop_configuration from '../../../shop-configuration';
import BookingConfirmationComponent from '../../../components/Bookings/BookingConfirmationComponent';
import { LoginViewComponent } from '../../../components/Auth/LoginViewComponent';
import RegisterViewComponent from '../../../components/Auth/RegisterViewComponent';
import { AppContext } from '../../../AppContextProvider';
import UserService from '../../../shared/lib/services/UserService';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

const Home: React.FC = () => {
    const globalState = useContext(AppContext);
    const { state } = globalState;
    const { dispatch } = globalState;

    const updateUser = async () => {
        UserService.get(state.tokens).then((data: any) => {
            //console.log(data);
            let tokens = state.tokens;
            dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: tokens, user: data } });
            storeUser(data);
            console.log('user updated', data);
        });
    }
    const storeUser = async (data: User) => {
        Storage.set({
            key: 'user',
            value: JSON.stringify(data)
        });
    };

    const [loginOpen, setLoginOpen] = useState<boolean>(false);
    const [registerOpen, setRegisterOpen] = useState<boolean>(false);
    const [bookingOpen, setBookingOpen] = useState<boolean>(false);

    const [confirmedBooking, setConfirmedBooking] = useState<Booking>();
    const showBookingConfirmation = (booking: Booking) => {
        setConfirmedBooking(booking);
    }

    useEffect(() => {
        console.log('updated state from business page: ', globalState);
    }, [globalState]);

    useEffect(()=>{
        updateUser();
    },[]);


    return (
        <IonPage>
            <IonHeader>
            </IonHeader>
            <IonContent style={{ "--background": "url('assets/retail-bg.jpg') center/cover no-repeat" }} className="ion-padding background">
                <div className="ion-text-center">
                    <IonImg className="retail-logo" src="assets/logo_retail.svg"></IonImg>
                </div>
                <div className="welcome-content">
                    {((state && state.user) ?
                        <>
                            <IonText color="light"><h1 className="welcome-heading">Ciao,<br></br>{state.user.firstname}</h1></IonText>
                            <IonButton onClick={() => { setBookingOpen(true); }} className="book-btn" expand="block">Prenota ora</IonButton>
                        </>
                        :
                        <>
                            <IonText color="light"><h1 className="welcome-heading">Benvenuto</h1></IonText>
                            <IonButton className="" onClick={() => { setLoginOpen(true); }} expand="block" color="primary">Accedi e prenota</IonButton>

                        </>)}

                </div>
                <IonModal cssClass='confirm-modal' isOpen={confirmedBooking !== undefined}>
                    {(confirmedBooking && <BookingConfirmationComponent booking={confirmedBooking} close={() => { setConfirmedBooking(undefined) }}></BookingConfirmationComponent>)}
                </IonModal>
                <IonModal isOpen={loginOpen}>
                    <LoginViewComponent dismiss={() => { setLoginOpen(false); }} toRegister={() => { setLoginOpen(false); setRegisterOpen(true); }} onAccess={() => { setLoginOpen(false); }}></LoginViewComponent>
                </IonModal>
                <IonModal isOpen={registerOpen}>
                    <RegisterViewComponent dismiss={() => { setRegisterOpen(false); }} toLogin={() => { setLoginOpen(true); setRegisterOpen(false); }} onAccess={() => { setRegisterOpen(false); }}></RegisterViewComponent>
                </IonModal>
                <IonModal cssClass="booking-modal" onDidDismiss={() => { setBookingOpen(false); }} swipeToClose={true} isOpen={bookingOpen}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                Nuova prenotazione
                        </IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => { setBookingOpen(false); }}>Annulla</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <BookingCardFlow onBookingComplete={(booking: Booking) => { showBookingConfirmation(booking); updateUser(); setBookingOpen(false); }} business={shop_configuration}></BookingCardFlow>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default Home;
