import React from 'react';
import { IonItem, IonText, IonLabel, IonNote, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonButtons, IonButton, getPlatforms } from '@ionic/react';
import Moment from 'react-moment';
// import './ExploreContainer.css';
import 'moment/locale/it';
import { Calendar } from '@ionic-native/calendar';
import moment from 'moment';
import { book } from 'ionicons/icons';
import { isPlatform } from '@ionic/react';


interface ItemProps {
    booking: Booking,
    close: Function
}


const BookingConfirmationComponent: React.FC<ItemProps> = ({ booking, close }) => {

    const addToCalendar = () => {
        const calendar = Calendar;
        calendar.createEventInteractively(booking.service.name + ' con ' + booking.operator.name, undefined, '', moment(booking.date + ' ' + booking.start).toDate(), moment(booking.date + ' ' + booking.start).toDate()).then(
            (msg) => { console.log(msg); },
            (err) => { console.log(err); }
        );
    }


    return (

        <IonCard className="confirmation-card">
            <IonCardHeader>
                <IonCardTitle className="ion-text-center">🗓 Appuntamento Confermato! 🎉</IonCardTitle>
                <IonCardSubtitle className="ion-text-center ion-margin-top ion-margin-bottom">
                    <Moment locale="it" format="dddd D MMMM - HH:mm">{booking.date + ' ' + booking.start}</Moment>
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent className="ion-text-center ion-padding-top">
                <p>Il tuo appuntamento è stato correttamente fissato. Puoi aggiungerlo al calendario e ricevere un promemoria poco prima.</p>
                {(!isPlatform('mobileweb') && !isPlatform('desktop') && <IonButton expand="block" onClick={() => { close(); addToCalendar(); }} className="ion-margin-top">Aggiungi al calendario</IonButton>)}
                <IonButton onClick={() => { close(); }} fill="clear" expand="block" color="dark" className="ion-margin-top">Chiudi</IonButton>
            </IonCardContent>
        </IonCard >
    );
};

export default BookingConfirmationComponent;
