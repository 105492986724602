import request from '../request'

function search(keyword: string | null) {
    return request({
        url: `/app`,
        method: 'GET',
        params:{
            'keyword':keyword
        }
    });
}

function get(slug: string) {
    return request({
        url: `/app/${slug}`,
        method: 'GET'
    });
}


const AppsService = {
    search, get //, update, delete, etc. ...
}

export default AppsService;