import React from 'react';
import { IonItem, IonText, IonLabel } from '@ionic/react';
import Moment from 'react-moment';
import 'moment/locale/it';
import 'moment-timezone';

interface ItemProps {
    slot: string,
    action: Function
}


const SlotItem: React.FC<ItemProps> = ({ slot, action }) => {
    console.log(slot);
    return (
        <IonItem onClick={() => action(slot)}>
            <IonLabel className="ion-text-wrap spaced-label">
                <IonText>
                    <Moment locale="it" format="HH:mm">{slot}</Moment>
                </IonText>
            </IonLabel>
        </IonItem>
    );
};

export default SlotItem;
