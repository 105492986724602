import React from 'react';
import { IonItem, IonText, IonLabel } from '@ionic/react';
// import './ExploreContainer.css';

interface ItemProps {
    shop: Shop,
    action: Function
}


const ShopItem: React.FC<ItemProps> = ({ shop, action }) => {
    return (

        <IonItem onClick={() => action(shop)}>
            <IonLabel className="ion-text-wrap spaced-label">
                <IonText>
                    <h1>{shop.name}</h1>
                    {!(shop.address) || <p>{shop.address}</p>}
                </IonText>
            </IonLabel>

        </IonItem>
    );
};

export default ShopItem;
