import { IonContent, IonPage, IonModal, IonHeader, IonToolbar } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import SearchInput from '../../components/BusinessSearch/SearchInput/SearchInput';
import SearchList from '../../components/BusinessSearch/SearchList/SearchList';
import BusinessPageComponent from '../../components/BusinessPageComponent/BusinessPageComponent';


import AppsService from '../../shared/lib/services/AppsService';
import { RouteComponentProps } from 'react-router-dom';

interface BusinessPageProps extends RouteComponentProps<{
    customslug: string;
}> { }


const BusinessSearch: React.FC<BusinessPageProps> = ({ match }) => {

    const slug: string = match.params.customslug;

    const [results, setResults] = useState<Business[]>([]);
    const searchBusiness = (keyword: string) => {

        if (keyword.length < 3) { setResults([]); }
        else {
            AppsService.search(keyword).then((data) => { console.log(data); setResults(data); });
        }
    }

    const [businessOpened, setBusinessOpened] = useState<boolean>(false);
    const [selectedBusiness, setSelectedBusiness] = useState<Business | undefined>();

    const loadBusiness = (slug: string | undefined) => {
        if (slug) AppsService.get(slug).then((data) => { console.log(data); if(data){setSelectedBusiness(data);setBusinessOpened(true);} });
    }

    useEffect(() => {
        loadBusiness(slug);
    }, []);



    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <SearchInput placeholder="Scrivi qui il nome..." disabled={false} onChange={(keyword: string) => { searchBusiness(keyword); }} ></SearchInput>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {(results.length > 0 && <SearchList onSelect={(business: Business) => { setSelectedBusiness(business); setBusinessOpened(true); }} results={results}></SearchList>)}
            </IonContent>
            {(selectedBusiness &&
                <IonModal onDidDismiss={() => { setSelectedBusiness(undefined); setBusinessOpened(false); }} isOpen={businessOpened}>
                    <BusinessPageComponent onClose={() => { setBusinessOpened(false); setSelectedBusiness(undefined); }} business={selectedBusiness}></BusinessPageComponent>
                </IonModal>)}
        </IonPage>

    );
};

export default BusinessSearch;
