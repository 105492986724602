
import React, { createContext, useReducer, useState, useEffect, useContext } from "react";
// import Reducer from './Reducer'
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;


const initialState: any = {
    user: null,
    tokens: null
};
const AppContext = React.createContext(initialState);
export { AppContext };

const AppContextProvider: React.FC<any> = ({ children }) => {


    // const [updatedState, setUpdatedState] = useState<any>(initialState);



    let [state, dispatch] = useReducer((state: any, action: any) => {
        switch (action.type) {
            case 'SET_AUTH':
                return {
                    tokens: action.payload.tokens,
                    user: action.payload.user
                };
            case 'REMOVE_AUTH':
                return {
                    tokens: null,
                    user: null
                };
            case 'SET_USER':
                return {
                    tokens: state.tokens,
                    user: action.payload.user
                };
            case 'REMOVE_USER':
                return {
                    ...state,
                    user: null
                };
            case 'SET_TOKENS':
                return {
                    ...state,
                    tokens: action.payload
                };
            case 'REMOVE_TOKENS':
                return {
                    ...state,
                    tokens: null
                };
                // default:
                //     return state;

                console.log(state);
        };
    }, initialState);

    useEffect(() => {
        checkLocal();
    }, []);

    const checkLocal = async () => {

        let user: any = null;
        let tokens: any = null;

        Storage.get({ key: 'tokens' }).then((ret) => {
            if (ret.value) { tokens = JSON.parse(ret.value); }
            Storage.get({ key: 'user' }).then((ret2) => {
                if (ret2.value) { user = JSON.parse(ret2.value); }
                dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: tokens, user: user } });
            });
        });
    }


    return (
        <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
    );
}

export { AppContextProvider };
